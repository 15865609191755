<template>
  <div id="navbar-collapse-icon">
    <svg viewBox="0 0 800 600" :class="expanded ? 'expanded' : ''" >
      <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
      <path d="M300,320 L540,320" id="middle"></path>
      <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "NavbarCollapseIcon",
  props: {
    expanded: Boolean
  }
}
</script>

<style lang="scss" scoped>
#navbar-collapse-icon {
  width: 5rem;
  height: 4rem;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  path {
    fill: none;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 40px;
    stroke-linecap: round;
    stroke: #707070;
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  .expanded path#top,
  .expanded path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  .expanded path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
}
</style>