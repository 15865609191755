
import Prismic from "@prismicio/client";
import PrismicHelper from "@/helper/prismichelper";
import CTX from "@/helper/contexthelper";

const blog = {
  namespaced: true,

  state: {
    shortPosts: [], // partial blog objects
    blogPosts: [],
    updatedShortPosts: false
  },

  mutations: {
    updateShortPosts(state, shortPosts) {
      state.updateShortPosts = true;
      state.shortPosts = shortPosts;
    },
    addBlogPost(state, blogPost) {
      if (blogPost && !state.blogPosts.includes(blogPost)) {
        state.blogPosts.push(blogPost);
      }
    },
  },

  getters: {
    blogPost: state => (uid, isAppLive) => CTX.filterPreviewItems(state.blogPosts, isAppLive).find(p => p.uid === uid),
    shortPosts: state => isAppLive => CTX.filterPreviewItems(state.shortPosts, isAppLive),
    sortedShortPosts: state => isAppLive =>
      CTX.filterPreviewItems(state.shortPosts, isAppLive).sort(
        (a, b) => new Date(b.data.date) - new Date(a.data.date)
      ),
    featuredShortPost: state => isAppLive => CTX.filterPreviewItems(state.shortPosts, isAppLive).find(p => p.data.featured_post),
    // latestShortPost: (state, getters) => isAppLive => CTX.filterPreviewItems(getters.sortedShortPosts[0], isAppLive),
  },

  actions: {
    async fetchShortPosts({ commit, state }) {
      if (!state.updatedShortPosts) {
        const query = PrismicHelper.createQueryWithLink(
          "blog",
          ["title", "date", "picture", "featured_post", "preview"],
          "author",
          "author",
          ["first_name", "picture"]
        );
        await this._vm.$prismic.client
          .query(Prismic.Predicates.at("document.type", "blog"), {
            graphQuery: query, pageSize: 500
          })
          .then(document => {
            if (document)
              commit("updateShortPosts", document.results)
          });
      }
    },
    async fetchBlogPost({ commit }, uid) {
      await this._vm.$prismic.client
        .getByUID("blog", uid, { "fetchLinks": "author.full_name, author.description, author.picture, author.linkedin" })
        .then(document => commit("addBlogPost", document));
    },
  }
}

export default blog;
