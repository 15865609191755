class ContextHelper {
  static isAppLive(location) {
    return location.includes("reducer.co.uk")
  }
  static filterPreviewItems(items, isAppLive) {
    if (items && typeof(items) != undefined) {
      return items.filter(c => isAppLive ? !c.data.preview : true)
    } else return items;
  }
}

export default ContextHelper;