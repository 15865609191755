class PrismicHelper {

  // Creates a Prismic GaphQuery for a given document type with given fields
  static createQuery(type, ...fields) {
    let q = '';

    fields.forEach(f => q += '\n' + f);
    q = `{\n${type} {${q}\n}\n}`;

    return q;
  }

  /* 
  Creates a Prismic GaphQuery for a given document type with given fields
  and a linked document with given fields
  @param type             Document type (blog)
  @param [...fields]      Document fields (title)
  @param linkName         Name of a linked document field (author)
  @param linkType         Type of a linked document (author)
  @param [...linkFields]  Linked document fields (first_name)
  */
  static createQueryWithLink(type, [...fields], linkName, linkType, [...linkFields]) {
    let q = '';

    fields.forEach(f => q += '\n' + f);
    q += `\n${linkName} {\n...on ${linkType} {`

    linkFields.forEach(f => q += '\n' + f);
    q = `{\n${type} {${q}\n}\n}\n}\n}`;

    return q;
  }
}

export default PrismicHelper;