<template>
  <div id="app" data-spy="scroll">
    <Navbar v-b-visible.once="navbarVisibleHandler" />
    <!-- Webinar alert bar
    <WebinarAlert />
    -->
    <transition name="fade" mode="out-in">
      <router-view v-if="navbarvisible" v-b-visible.once="routeVisibleHandler" />
    </transition>
    <Footer v-if="routervisible" totop />
  </div>
</template>

<script>
import Navbar from "@/components/nav/Navbar.vue";
import Footer from "@/components/nav/Footer";
import WebinarAlert from "./components/parts/WebinarAlert.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    WebinarAlert
  },
  data() {
    return {
      navbarvisible: false,
      routervisible: false
    };
  },
  methods: {
    //Prevent main content showing displaying before navbar on first page load. Reduces CLS.
    navbarVisibleHandler(isVisible) {
      if (isVisible) {
        this.navbarvisible = true;
      }
    },
    //Prevent footer showing displaying before content on first page load. Reduces CLS.
    routeVisibleHandler(isVisible) {
      if (isVisible) {
        this.routervisible = true;
      }
    }
  }
};
</script>

<style style="scss">
@import "./assets/css/resetr.css";
@import "./assets/css/prismic-edit-button.css";
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
