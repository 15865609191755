import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default [
  {
    path: "/accountants-near-london",
    name: "accountants-near-london",
    props: { location: "London", area: 2 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-birmingham",
    name: "accountants-near-birmingham",
    props: { location: "Birmingham", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-manchester",
    name: "accountants-near-manchester",
    props: { location: "Manchester", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-leeds",
    name: "accountants-near-leeds",
    props: { location: "Leeds", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-newcastle",
    name: "accountants-near-newcastle",
    props: { location: "Newcastle", area: 50 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-glasgow",
    name: "accountants-near-glasgow",
    props: { location: "Glasgow", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-liverpool",
    name: "accountants-near-liverpool",
    props: { location: "Liverpool", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-southampton",
    name: "accountants-near-southampton",
    props: { location: "Southampton", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-portsmouth",
    name: "accountants-near-portsmouth",
    props: { location: "Portsmouth", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-nottingham",
    name: "accountants-near-nottingham",
    props: { location: "Nottingham", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-bristol",
    name: "accountants-near-bristol",
    props: { location: "Bristol", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-sheffield",
    name: "accountants-near-sheffield",
    props: { location: "Sheffield", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-hull",
    name: "accountants-near-hull",
    props: { location: "Kingston Upon Hull", area: 30 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-leicester",
    name: "accountants-near-leicester",
    props: { location: "Leicester", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-edinburgh",
    name: "accountants-near-edinburgh",
    props: { location: "Edinburgh", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-cardiff",
    name: "accountants-near-cardiff",
    props: { location: "cardiff", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-stoke",
    name: "accountants-near-stoke",
    props: { location: "Stoke on Trent", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-coventry",
    name: "accountants-near-coventry",
    props: { location: "Coventry", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-reading",
    name: "accountants-near-reading",
    props: { location: "Reading", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-belfast",
    name: "accountants-near-belfast",
    props: { location: "Belfast", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-derby",
    name: "accountants-near-derby",
    props: { location: "Derby", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-plymouth",
    name: "accountants-near-plymouth",
    props: { location: "Plymouth", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-wolverhampton",
    name: "accountants-near-wolverhampton",
    props: { location: "Wolverhampton", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-swansea",
    name: "accountants-near-swansea",
    props: { location: "Swansea", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-miltonkeynes",
    name: "accountants-near-miltonkeynes",
    props: { location: "Milton Keynes", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-islington",
    name: "accountants-near-islington",
    props: { location: "Islington", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-aberdeen",
    name: "accountants-near-aberdeen",
    props: { location: "Aberdeen", area: 30 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-luton",
    name: "accountants-near-luton",
    props: { location: "Luton", area: 15 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-norwich",
    name: "accountants-near-norwich",
    props: { location: "Norwich", area: 30 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-croydon",
    name: "accountants-near-croydon",
    props: { location: "Croydon", area: 7 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-swindon",
    name: "accountants-near-swindon",
    props: { location: "Swindon", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-basildon",
    name: "accountants-near-basildon",
    props: { location: "Basildon", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-bournemouth",
    name: "accountants-near-bournemouth",
    props: { location: "Bournemouth", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-worthing",
    name: "accountants-near-worthing",
    props: { location: "Worthing", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-ipswich",
    name: "accountants-near-ipswich",
    props: { location: "Ipswich", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-sunderland",
    name: "accountants-near-sunderland",
    props: { location: "Sunderland", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-ilford",
    name: "accountants-near-ilford",
    props: { location: "Ilford", area: 10 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-middlesbrough",
    name: "accountants-near-middlesbrough",
    props: { location: "Middlesbrough", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-warrington",
    name: "accountants-near-warrington",
    props: { location: "Warrington", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-huddersfield",
    name: "accountants-near-huddersfield",
    props: { location: "Huddersfield", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-slough",
    name: "accountants-near-slough",
    props: { location: "Slough", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-oxford",
    name: "accountants-near-oxford",
    props: { location: "Oxford", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-york",
    name: "accountants-near-york",
    props: { location: "York", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-harrow",
    name: "accountants-near-harrow",
    props: { location: "Harrow", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
  {
    path: "/accountants-near-poole",
    name: "accountants-near-poole",
    props: { location: "Poole", area: 20 },
    component: () => import("./views/FindAccountants.vue"),
  },
];
