import Vue from "vue";
import Router from "vue-router";
import nearMeRoutes from "@/router_accountants_near_me";

Vue.use(Router);

const baseRoutes = [
  {
    path: "/accountants",
    redirect: to => {
      setPreRenderRedirect("https://reducer.co.uk");
      return "/";
    }
  },
  {
    path: "/",
    name: "accountants",
    component: () => import(/* webpackChunkName: "home" */ "./views/Accountants.vue")
  },
  {
    path: "/businesses",
    name: "businesses",
    component: () => import(/* webpackChunkName: "businesses" */ "./views/Businesses.vue")
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () => import(/* webpackChunkName: "cookies" */ "./views/policies/Cookies.vue")
  },
  {
    path: "/complaints",
    name: "complaints",
    component: () => import(/* webpackChunkName: "cookies" */ "./views/policies/Complaints.vue")
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "./views/policies/Privacy.vue")
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import(/* webpackChunkName: "terms" */ "./views/policies/Terms.vue")
  },
  {
    path: "/quickbooks",
    name: "quickbooks",
    component: () => import(/* webpackChunkName: "qbs" */ "./views/QuickBooks.vue")
  },
  {
    path: "/quickbooks-disconnect",
    name: "quickbooks-disconnect",
    component: () =>
      import(/* webpackChunkName: "qb-disconnect" */ "./views/QuickBooksDisconnect.vue")
  },
  {
    path: "/guides",
    name: "guides",
    component: () => import(/* webpackChunkName: "guides" */ "./views/Guides.vue")
  },
  // Guides redirects
  {
    path: "/guides/payment-processing",
    redirect: to => {
      setPreRenderRedirect("https://reducer.co.uk/guides/card-payments");
      return "/guides/card-payments";
    }
  },
  {
    path: "/guides/broadband-and-telecoms",
    redirect: to => {
      setPreRenderRedirect("https://reducer.co.uk/guides/business-broadband-and-telecoms");
      return "/guides/business-broadband-and-telecoms";
    }
  },
  {
    path: "/guides/electricity",
    redirect: to => {
      setPreRenderRedirect("https://reducer.co.uk/guides/business-electricity");
      return "/guides/business-electricity";
    }
  },
  {
    path: "/guides/gas",
    redirect: to => {
      setPreRenderRedirect("https://reducer.co.uk/guides/business-gas");
      return "/guides/business-gas";
    }
  },
  {
    path: "/guides/water",
    redirect: to => {
      setPreRenderRedirect("https://reducer.co.uk/guides/business-water");
      return "/guides/business-water";
    }
  },
  // End guides redurects
  {
    path: "/guides/:uid",
    name: "guide",
    component: () => import(/* webpackChunkName: "guide" */ "./views/Guide.vue")
  },
  {
    path: "/join",
    name: "join",
    component: () => import(/* webpackChunkName: "join" */ "./views/join/Join.vue")
  },
  {
    path: "/v-join",
    name: "v-join",
    component: () => import(/* webpackChunkName: "v-join" */ "./views/join/VoucherJoin.vue")
  },
  {
    path: "/pchigh/join",
    name: "pchigh-join",
    component: () =>
      import(/* webpackChunkName: "pch-join" */ "./views/join/PriceComparisonHigh.vue")
  },
  {
    path: "/pclow/join",
    name: "pclow-join",
    component: () =>
      import(/* webpackChunkName: "pcl-join" */ "./views/join/PriceComparisonLow.vue")
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "./views/Faq.vue")
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "./views/AboutUs.vue")
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import(/* webpackChunkName: "preview" */ "./views/Preview.vue")
  },
  {
    path: "/xero",
    name: "xero",
    component: () => import(/* webpackChunkName: "xero" */ "./views/Xero.vue")
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import(/* webpackChunkName: "blog" */ "./views/Blog.vue")
  },
  {
    path: "/blog/:uid",
    name: "blog-post",
    component: () => import(/* webpackChunkName: "blog-post" */ "./views/BlogPost.vue")
  },
  {
    path: "/whitepaper",
    name: "whitepaper",
    component: () => import(/* webpackChunkName: "whitepaper" */ "./views/Whitepaper.vue")
  },
  {
    path: "/webinars",
    name: "webinars",
    component: () => import(/* webpackChunkName: "webinars" */ "./views/Webinars.vue")
  },
  {
    path: "/how-it-works",
    name: "how-it-works",
    component: () => import(/* webpackChunkName: "how-it-works" */ "./views/HowItWorks.vue")
  },
  {
    path: "/knowledge-hub",
    name: "knowledge-hub",
    component: () => import(/* webpackChunkName: "know-hub" */ "./views/KnowledgeHub.vue")
  },
  {
    path: "/case-studies/accountants",
    name: "accountant-case-studies",
    component: () => import(/* webpackChunkName: "acc-case-studs" */ "./views/CaseStudies.vue")
  },
  {
    path: "/case-studies/businesses",
    name: "business-case-studies",
    component: () => import(/* webpackChunkName: "bus-case-studs" */ "./views/CaseStudies.vue")
  },
  {
    path: "/case-studies/accountants/:uid",
    name: "accountant-case-study",
    component: () => import(/* webpackChunkName: "acc-case-study" */ "./views/CaseStudy.vue")
  },
  {
    path: "/case-studies/businesses/:uid",
    name: "business-case-study",
    component: () => import(/* webpackChunkName: "bus-case-study" */ "./views/CaseStudy.vue")
  },
  {
    path: "/business-spending-and-supplier-review",
    name: "spending-review",
    component: () => import(/* webpackChunkName: "spend-rev" */ "./views/SpendingReview.vue")
  },
  {
    path: "/business-spending-and-supplier-review-2021",
    name: "spending-review-2021",
    component: () =>
      import(/* webpackChunkName: "spend-rev-2021" */ "./views/SpendingReview2021.vue")
  },
  {
    path: "/accountants-near-me",
    name: "accountants-near-me",
    component: () => import(/* webpackChunkName: "acc-near-me" */ "./views/FindAccountants.vue")
  },
  {
    path: "/depeche-mode",
    name: "depeche-mode",
    component: () => import(/* webpackChunkName: "404" */ "./views/DepecheMode.vue")
  },
  {
    path: "/mersthamfc",
    name: "temp-mersham-fc-redirect",
    beforeEnter: () => {
      window.location.href =
        "https://reducer.co.uk/app/consent/38cec002-8bc3-4652-9fa0-3ed85f77d866";
    }
  },
  {
    path: "*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "404" */ "./views/NotFound.vue")
  }
];

function setPreRenderRedirect(url) {
  // Setting these headers will cause prerender to to return 301 redirects to crawlers
  // Without this crawlers will see both pages are duplicates
  const metaheadercode = document.createElement("meta");
  metaheadercode.name = "prerender-status-code";
  metaheadercode.content = "301";
  document
    .getElementsByTagName("HEAD")
    .item(0)
    .appendChild(metaheadercode);
  const metaheaderlocation = document.createElement("meta");
  metaheaderlocation.name = "prerender-header";
  metaheaderlocation.content = "Location: " + url;
  document
    .getElementsByTagName("HEAD")
    .item(0)
    .appendChild(metaheaderlocation);
}

const routes = baseRoutes.concat(nearMeRoutes);
export default new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
