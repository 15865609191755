import Prismic from "@prismicio/client";
import CTX from "@/helper/contexthelper";

const caseStudies = {
  namespaced: true,
  state: {
    caseStudies: [],
    updated: false
  },
  mutations: {
    updateCaseStudies(state, caseStudies) {
      state.caseStudies = caseStudies.sort(
        (a, b) => new Date(b.first_publication_date) - new Date(a.first_publication_date));
      state.updated = true;
    },
    addCaseStudy(state, caseStudy) {
      if (caseStudy && !state.caseStudies.includes(caseStudy)) {
        state.caseStudies.push(caseStudy);
      }
    },
  },
  getters: {
    caseStudy: state => (uid, isAppLive) => {
      return CTX.filterPreviewItems(state.caseStudies, isAppLive).find(c => c.uid == uid);
    },
    caseStudies: state => isAppLive => {
      return CTX.filterPreviewItems(state.caseStudies, isAppLive);
    },
  },
  actions: {
    async fetchCaseStudies({ commit, state }) {
      if (!state.updated) {
        await this._vm.$prismic.client
          .query(Prismic.Predicates.at("document.type", "case-study1"), { orderings: '[my.case-study1.date desc]', pageSize: 500 })
          .then(document => commit("updateCaseStudies", document.results))
      } else {
        return;
      }
    },
    async fetchCaseStudy({ commit }, uid) {
      await this._vm.$prismic.client
        .getByUID("case-study1", uid)
        .then(document => commit("addCaseStudy", document));
    }
  }
}

export default caseStudies;
