<template>
  <div class="email-newsletter">
    <div class="newsletter-header pb-2 mb-md-4">
      <h5 class="text-pink">Newsletter</h5>
      <h2>Fancy bi-monthly updates from Reducer?</h2>
    </div>
    <div id="mc_embed_signup" class="w-100">
      <form
        action="https://reducer.us8.list-manage.com/subscribe/post?u=2f2a14ef38678809ada046703&amp;id=c32f6055ff"
        method="post"
        id="mc-embedded-subscribe-form"
        ref="newsletter-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
        @submit.prevent="submitForm()"
      >
        <div id="mc_embed_signup_scroll">
          <b-row class="align-items-end">
            <b-col cols="12" md="6" class="mc-field-group pt-4 pt-md-0">
              <label for="mce-FNAME">Name *</label>
              <input type="text" value="" name="FNAME" class="form-control" id="mce-FNAME" />
            </b-col>
            <b-col cols="12" md="6" class="mc-field-group pt-4 pt-md-0">
              <label for="mce-EMAIL">Email *</label>
              <input
                type="email"
                value=""
                name="EMAIL"
                class="required email form-control"
                id="mce-EMAIL"
              />
            </b-col>
            <b-col cols="12" md="6" class="mc-field-group pt-4">
              <label for="mce-MMERGE8">Firm </label>
              <input type="text" value="" name="MMERGE8" class="form-control" id="mce-MMERGE8" />
            </b-col>
            <b-col class="clear text-center text-md-right pt-4">
              <b-button
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="landing-button primary px-4 py-0"
                pill
              >
                Send me updates
                <font-awesome-icon :icon="['fas', 'paper-plane']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display: none"></div>
            <div class="response" id="mce-success-response" style="display: none"></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_2f2a14ef38678809ada046703_c32f6055ff"
              tabindex="-1"
              value=""
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailNewsletter",
  methods: {
    submitForm() {
      const form = this.$refs['newsletter-form'];
      form.submit();
      form.reset();
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/main";
.email-newsletter {
  @include main-style;
  @include align-center-column;
  box-shadow: $soft-shadow;
  width: 95%;
  max-width: 65rem;
  padding: 2rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  h2 {
    font-size: 1.7rem !important;
  }
  .landing-button {
    transition: $smooth-transition;
    margin-top: 0.2rem;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  .newsletter-header {
    padding-bottom: 0.5rem !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
  }
  .text-pink {
    color: #ef90c5 !important;
    text-transform: uppercase;
  }
  .mc-field-group {
    display: flex;
    label {
      font-family: "Lato", sans-serif;
      font-weight: 500;
      font-size: 1.35rem;
      white-space: nowrap;
      width: 7rem;
    }
    input {
      border-width: 2px;
      margin: 0;
      width: 100%;
    }
  }
}
</style>
