<template>
  <!-- TODO change secondary hover color -->
  <b-button
    v-if="!routerLink"
    :class="`landing-button ${secondary ? 'secondary' : 'primary'}`"
    :style="buttonStyle"
    :href="bookACall ? 'https://calendly.com/reducer-rishi-sharma/meeting' : href"
    :target="newWindow || bookACall ? '_target' : '_self'"
    :disabled="disabled"
    pill
    @clicked="$emit('click')"
  >
    {{ text }}
    <slot></slot>
  </b-button>
  <b-button
    v-else
    :class="`landing-button ${secondary ? 'secondary' : 'primary'}`"
    :style="buttonStyle"
    :to="routerLink"
    :target="newWindow || bookACall ? '_target' : '_self'"
    :disabled="disabled"
    pill
    @clicked="$emit('click')"
  >
    {{ text }}
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: "LandingButton",
  props: {
    href: {
      type: String,
      required: false,
      default: "#"
    },
    // Open link in new window
    newWindow: {
      type: Boolean,
      required: false,
      default: false
    },
    // Text inside button
    text: {
      type: String,
      required: false
    },
    // Use secondary style
    secondary: {
      type: Boolean,
      required: false
    },
    // Font size in rem
    size: {
      type: Number,
      required: false,
      default: 1.3
    },
    disabled: Boolean,
    // Apply extra padding
    padded: {
      type: Boolean,
      required: false,
      default: false
    },
    bookACall: {
      type: Boolean,
      requred: false,
      default: false
    },
    routerLink: Object
  },
  computed: {
    buttonStyle: function() {
      let style = `font-size: ${this.size}rem;`;
      if (this.padded) {
        style += `padding: ${this.size / 4}rem ${this.size * 1.5}rem`;
      } else {
        style += `padding: 0 1rem`;
      }
      return style;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
