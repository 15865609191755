const faqs = {
  namespaced: true,

  state: {
    faqs: []
  },

  mutations: {
    updateFaqs(state, faqs) {
      state.faqs = faqs;
    }
  },

  getters: {
    faqs: state => state.faqs
  },

  actions: {
    async fetchFaqs({ commit }) {
      await this._vm.$prismic.client
        .getSingle("faqs")
        .then(document => commit("updateFaqs", document))
    }
  }
}

export default faqs;