import axios from "axios";
import { api } from "../config/reducerproperties";

class ApiHelper {
  static endPoint() {
    if (api.dev) {
      return api.devApiEndPoint;
    } else {
      return api.apiEndPoint;
    }
  }

  static async http() {
    axios.defaults.headers.common["Authorization"] = "Bearer M2pjNjhaYjFIRW4wdEZtNVFZc1VGY290bldLenFpSXNQTGFvU1BNUjdUdFBSQjZnN202NXA0Y1ZQVnNaSXI4OElxNDg3N3hVdXpyVVFUdng=";
    return axios;
  }
}
export default ApiHelper;