<template>
  <b-alert
    :show="active && allowed"
    dismissible
    variant="light"
    id="webinar-alert"
    class="text-center m-0"
    fade
  >
    <!-- If we get Xero nomination -->
    <TrophyIcon class="mr-3" wiggle :size="28" />
    <b
      >Intro to <span class="text-pink">Xero Emerging App of the Year</span>, Reducer. Join our
      webinar with Joe Gallard, CEO</b
    >

    <!-- Comment out if Xero alert in use 
    <b>Introduction to Reducer. Join our regular webinar with Joe Gallard, CEO</b>
    -->

    <div v-on:click="handleWebinarClick()" class="d-block d-md-inline-block">
      <landing-button
        :size="0.8"
        text="Learn more"
        class="m-0 ml-md-4 mt-2 mt-md-0 gtm-webinar-alert"
      ></landing-button>
    </div>
  </b-alert>
</template>

<script>
import TeamMemberPhoto from "@/components/parts/TeamMemberPhoto";
import TrophyIcon from "@/components/icons/TrophyIcon";
import LandingButton from "../landing/LandingButton.vue";

export default {
  name: "WebinarAlert",
  data() {
    return {
      active: true
    };
  },
  components: {
    LandingButton,
    TrophyIcon
  },
  computed: {
    allowed: () => {
      var unauthenticatedRoutes = ["join"];
      if (window.location.href.indexOf("join") > -1) {
        return false;
      } else return true;
    }
  },
  methods: {
    async handleWebinarClick() {
      this.active = false;
      this.$router.push({ name: "webinars" });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
#webinar-alert {
  @include main-style;
  // Match the padding on the right due to dismiss button
  padding-left: 4em;
  z-index: 9000;
  box-shadow: $soft-shadow;
  position: -webkit-sticky;
  position: sticky;
  top: 5.2rem;
  color: $primary-grey;
  font-family: "Poppins", sans-serif !important;
  .close {
    position: block !important;
    margin: 0.2em 0 0 0 !important;
  }
}
</style>

