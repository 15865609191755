import Prismic from "@prismicio/client";
import PrismicHelper from "@/helper/prismichelper";

const guides = {
  namespaced: true,

  state: {
    guideNames: [], // guide names
    shortGuides: [], // partial guide objects
    guides: [],
    guideOrder: ["electricity", "gas", "card-payments", "broadband-and-telecoms", "water"]
  },

  mutations: {
    updateGuides(state, guides) {
      state.guides = guides;
    },
    updateGuideNames(state, guideNames) {
      state.guideNames = guideNames;
    },
    updateShortGuides(state, shortGuides) {
      state.shortGuides = shortGuides;
    },
    addGuide(state, guide) {
      if (guide && !state.guides.includes(guide)) {
        state.guides.push(guide);
      }
    }
  },

  getters: {
    guideNames: state => state.guideNames,
    sortedGuideNames: state =>
      state.guideNames.sort((a, b) =>
        a.data.shorttitle[0].text > b.data.shorttitle[0].text ? 1 : -1
      ),
    shortGuides: state => state.shortGuides,
    sortedShortGuides: state => {
      let sortedGuides = [];
      state.guideOrder.forEach(k => {
        let found = state.shortGuides.find(g => g.uid == k);
        if (found) {
          sortedGuides.push(found);
        }
      })
      let newGuides = state.shortGuides.filter(g => !state.guideOrder.includes(g.uid));
      return sortedGuides.concat(newGuides);
    },
    guide: state => uid => state.guides.find(g => g.uid === uid)
  },

  actions: {
    async fetchGuideNames({ commit }) {
      const query = PrismicHelper.createQuery("guide", "shorttitle");
      await this._vm.$prismic.client
        .query(Prismic.Predicates.at("document.type", "guide"), {
          graphQuery: query
        })
        .then(document => commit("updateGuideNames", document.results));
    },
    async fetchShortGuides({ commit }) {
      const query = PrismicHelper.createQuery(
        "guide",
        "title",
        "image",
        "description"
      );
      await this._vm.$prismic.client
        .query(Prismic.Predicates.at("document.type", "guide"), {
          graphQuery: query
        })
        .then(document => commit("updateShortGuides", document.results));
    },
    async fetchGuide({ commit }, uid) {
      await this._vm.$prismic.client
        .getByUID("guide", uid)
        .then(document => commit("addGuide", document));
    }
  }
};

export default guides;
