import ApiHelper from "@/helper/apihelper";

const whitepaper = {
  namespaced: true,

  state: {
    whitepaper: [],
    contributors: [],
    form: {}
  },

  mutations: {
    updateWhitepaper(state, whitepaper) {
      state.whitepaper = whitepaper;
    },
    updateContributors(state, contributors) {
      state.contributors = contributors;
    },
    updateForm(state, form) {
      state.form = form;
    }
  },
  getters: {
    whitepaper: state => state.whitepaper,
    contributors: state => state.contributors,
    form: state => state.form
  },
  actions: {
    async fetchWhitepaper({ commit }) {
      await this._vm.$prismic.client
        .getByUID("landing_page", "landing-page", {
          fetchLinks: ["landing_page_contributor.name",
            "landing_page_contributor.image",
            "landing_page_contributor.description",
            "landing_page_contributor.facebook",
            "landing_page_contributor.twitter",
            "landing_page_contributor.linkedin",
            "landing_page_contributor.personal_linkedin",
            "landing_page_contributor.company_image",
            "landing_page_contributor.website"]
        })
        .then(document => {
          let contributors = [];
          document.data.contributors.forEach(c => contributors.push(c.contributor));
          commit("updateWhitepaper", document.data);
          commit("updateContributors", contributors);
        })
    },
    async submitForm({ commit }, form) {
      const client = await ApiHelper.http();
      await client.post(`${ApiHelper.endPoint()}whitepaper`, form);
    }
  },
}

export default whitepaper;