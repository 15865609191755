import "intersection-observer/intersection-observer";
import Vuex from "vuex";
import { store } from "./store";
import Vue from "vue";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./prismic/link-resolver";
import htmlSerializer from "./prismic/html-serializer";
import App from "./App.vue";
import router from "./router";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vueHeadful from "vue-headful";

//Start bootstrap vue plugins
//If plugin is required on only a few pages, import it directly in components/vues, not here
//For example see BSkeleton in BlogCard
import { LayoutPlugin } from "bootstrap-vue";
Vue.use(LayoutPlugin);
import { NavbarPlugin } from "bootstrap-vue";
Vue.use(NavbarPlugin);
import { ButtonPlugin } from "bootstrap-vue";
Vue.use(ButtonPlugin);
import { AspectPlugin } from "bootstrap-vue";
Vue.use(AspectPlugin);
import { VBVisiblePlugin } from "bootstrap-vue";
Vue.use(VBVisiblePlugin);
import { AlertPlugin } from "bootstrap-vue";
Vue.use(AlertPlugin);
import { CardPlugin } from "bootstrap-vue";
Vue.use(CardPlugin);
import { LinkPlugin } from "bootstrap-vue";
Vue.use(LinkPlugin);
import { BadgePlugin } from "bootstrap-vue";
Vue.use(BadgePlugin);
import { SpinnerPlugin } from "bootstrap-vue";
Vue.use(SpinnerPlugin);
import { FormInputPlugin } from "bootstrap-vue";
Vue.use(FormInputPlugin);
import { ImagePlugin } from "bootstrap-vue";
Vue.use(ImagePlugin);
import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);
import { EmbedPlugin } from "bootstrap-vue";
Vue.use(EmbedPlugin);
import { ListGroupPlugin } from "bootstrap-vue";
Vue.use(ListGroupPlugin);
import { FormGroupPlugin } from "bootstrap-vue";
Vue.use(FormGroupPlugin);
import { FormPlugin } from "bootstrap-vue";
Vue.use(FormPlugin);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
//End bootstrap vue plugins

//Import font awesome style explicity. Inserting style into DOM automatically violates Content Security Policy
config.autoAddCss = false;
import "@fortawesome/fontawesome-svg-core/styles.css";

Vue.component("vue-headful", vueHeadful);

import {
  faPlusCircle,
  faMinusCircle,
  faPlus,
  faEnvelope,
  faCheck,
  faAngleDown,
  faCheckCircle,
  faChevronLeft,
  faStar,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

import { faClock } from "@fortawesome/free-regular-svg-icons";

import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

library.add(
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faPlus,
  faClock,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faEnvelope,
  faCheck,
  faAngleDown,
  faChevronLeft,
  faStar,
  faPaperPlane
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(PrismicVue, {
  endpoint: "https://reducer.prismic.io/api/v2",
  linkResolver,
  htmlSerializer
});
Vue.use(Vuex);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
