import Vue from "vue";
import Vuex from "vuex";
import blog from "./modules/blog";
import guides from "./modules/guides";
import faqs from "./modules/faqs";
import webinars from "./modules/webinars";
import whitepaper from "./modules/whitepaper";
import caseStudies from "./modules/casestudies";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    blog,
    guides,
    faqs,
    whitepaper,
    webinars,
    caseStudies
  }
});

