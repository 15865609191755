<template>
  <div class="trophy-icon" :wiggle="wiggle">
    <svg
      id="Capa_1"
      enable-background="new 0 0 512 512"
      :height="size"
      viewBox="0 0 512 512"
      :width="size"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m150.399 239.282c-85.166-7.998-150.399-78.618-150.399-164.282v-30c0-24.814 20.186-45 45-45s45 20.186 45 45c0 8.276 6.724 15 15 15h31c8.291 0 15 6.709 15 15s-6.709 15-15 15h-31c-24.814 0-45-20.186-45-45 0-8.276-6.724-15-15-15s-15 6.724-15 15v30c0 70.093 53.544 127.866 123.212 134.399 8.247.776 14.297 8.101 13.535 16.348-.776 8.221-8.056 14.289-16.348 13.535z"
          fill="#ffbe40"
        />
        <path
          d="m346.253 225.747c-.762-8.247 5.288-15.571 13.535-16.348 69.668-6.533 122.212-64.306 122.212-134.399v-30c0-8.276-6.724-15-15-15s-15 6.724-15 15c0 24.814-20.186 45-45 45h-30c-8.291 0-15-6.709-15-15s6.709-15 15-15h30c8.276 0 15-6.724 15-15 0-24.814 20.186-45 45-45s45 20.186 45 45v30c0 85.664-64.233 156.284-149.399 164.282-8.291.754-15.572-5.313-16.348-13.535z"
          fill="#ff9f40"
        />
        <path
          d="m377 0h-121-120c-8.291 0-15 6.709-15 15v150c0 57.349 36.46 107.944 90 126.841v115.159h45 45v-115.159c53.54-18.896 91-69.492 91-126.841v-150c0-8.291-6.709-15-15-15z"
          fill="#ffdf40"
        />
        <path
          d="m301 291.841c53.54-18.896 91-69.492 91-126.841v-150c0-8.291-6.709-15-15-15h-121v407h45z"
          fill="#ffbe40"
        />
        <path
          d="m332.611 115.664c-1.772-5.435-6.46-9.39-12.114-10.21l-35.273-5.127-15.776-31.963c-2.52-5.127-7.983-7.69-13.447-7.69s-10.928 2.563-13.447 7.69l-15.776 31.963-35.273 5.127c-5.654.82-10.342 4.775-12.114 10.21-1.758 5.42-.293 11.382 3.794 15.366l25.532 24.888-6.021 35.127c-.967 5.625 1.348 11.309 5.962 14.663 4.614 3.369 10.796 3.809 15.791 1.143l31.551-16.568 31.553 16.567c4.979 2.64 11.142 2.254 15.791-1.143 4.614-3.354 6.929-9.038 5.962-14.663l-6.021-35.127 25.532-24.888c4.087-3.983 5.552-9.945 3.794-15.365z"
          fill="#ffbe40"
        />
        <path
          d="m303.344 205.708c4.614-3.354 6.929-9.038 5.962-14.663l-6.021-35.127 25.532-24.888c4.087-3.984 5.552-9.946 3.794-15.366-1.772-5.435-6.46-9.39-12.114-10.21l-35.273-5.127-15.776-31.963c-2.52-5.127-7.983-7.69-13.447-7.69v129.609l31.553 16.567c4.977 2.641 11.141 2.255 15.79-1.142z"
          fill="#ff9f40"
        />
        <path
          d="m376 452h-15v-45c0-8.291-6.709-15-15-15h-90-90c-8.291 0-15 6.709-15 15v45h-15c-8.291 0-15 6.709-15 15v30c0 8.291 6.709 15 15 15h120 120c8.291 0 15-6.709 15-15v-30c0-8.291-6.709-15-15-15z"
          fill="#4d6699"
        />
        <path
          d="m391 497v-30c0-8.291-6.709-15-15-15h-15v-45c0-8.291-6.709-15-15-15h-90v120h120c8.291 0 15-6.709 15-15z"
          fill="#404b80"
        />
        <path
          d="m286 437h-30-30c-8.291 0-15 6.709-15 15s6.709 15 15 15h30 30c8.291 0 15-6.709 15-15s-6.709-15-15-15z"
          fill="#404b80"
        />
        <path d="m301 452c0-8.291-6.709-15-15-15h-30v30h30c8.291 0 15-6.709 15-15z" fill="#336" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "TrophyIcon",
  props: {
    size: {
      type: Number,
      default: 18
    },
    wiggle: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.trophy-icon {
  display: inline-block;

  &[wiggle="true"] {
    animation: wiggle 2.5s infinite;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(5deg);
    }
    95% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
</style>