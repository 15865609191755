<template>
  <b-navbar id="navbar" toggleable="xl" type="light" sticky>
    <b-navbar-brand to="/">
      <img alt="Reducer Logo" src="/images/reducer_logo.svg" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" ref="toggle">
      <template v-slot:default="{ expanded }">
        <NavbarCollapseIcon :expanded="expanded" />
      </template>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{ name: 'accountants' }">Accountants</b-nav-item>
        <b-nav-item :to="{ name: 'businesses' }">Businesses</b-nav-item>
        <b-nav-item :to="{ name: 'how-it-works' }">How It Works</b-nav-item>
        <b-nav-item href="https://reducer.co.uk/app/demo" target="_blank">Demo</b-nav-item>
        <b-nav-item-dropdown no-caret right>
          <template v-slot:button-content>
            Resources
            <font-awesome-icon :icon="['fas', 'angle-down']" class="navbar-dropdown-icon" />
          </template>
          <b-dropdown-item :to="{ name: 'knowledge-hub' }">Knowledge Hub</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'blog' }">Blog</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'webinars' }">Webinars</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'accountants-near-me' }">Find Accountants</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-header>Buying Guides</b-dropdown-header>
          <b-dropdown-item to="/guides/business-broadband-and-telecoms"
            >Broadband &#38; Telecoms</b-dropdown-item
          >
          <b-dropdown-item to="/guides/card-payments">Card Payments</b-dropdown-item>
          <b-dropdown-item to="/guides/business-electricity">Electricity</b-dropdown-item>
          <b-dropdown-item to="/guides/business-gas">Gas</b-dropdown-item>
          <b-dropdown-item to="/guides/business-water">Water</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown no-caret right>
          <template v-slot:button-content>
            About
            <font-awesome-icon :icon="['fas', 'angle-down']" class="navbar-dropdown-icon" />
          </template>
          <b-dropdown-item :to="{ name: 'about' }">About Us</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'accountant-case-studies' }">Case Studies</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'faq' }">FAQs</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'xero' }">Xero</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'quickbooks' }">QuickBooks</b-dropdown-item>
        </b-nav-item-dropdown>
        <landing-button id="navbar-sign-in" text="Sign-in" :size="1.1" href="/app/sign-in" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import LandingButton from "@/components/landing/LandingButton";
import NavbarCollapseIcon from "@/components/icons/NavbarCollapseIcon";

export default {
  name: "Navbar",
  components: {
    LandingButton,
    NavbarCollapseIcon
  },
  methods: {
    closeOnClick() {
      document
        .querySelectorAll("#navbar a.nav-link:not(.dropdown-toggle), #navbar a.dropdown-item")
        .forEach((node) =>
          node.addEventListener("click", () => {
            // Scroll to top if clicked on the currently open page
            if (node.classList.contains("router-link-active")) {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }
            // Close dropdown menu
            if (this.$refs.toggle.$el.classList.contains("not-collapsed")) {
              this.$root.$emit("bv::toggle::collapse", "nav-collapse");
            }
          })
        );
    }
  },
  updated() {
    this.closeOnClick();
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

#navbar {
  @include main-style;
  z-index: 9999;
  background-color: white;
  box-shadow: $side-shadow;
  padding: 0 1rem !important;
  margin-bottom: 1rem;
  @include lg-screen {
    padding: 0.5rem 1rem !important;
  }
  .navbar-brand img {
    height: 2rem;
    margin: 0.3rem 0;
  }
  // Navbar items styling
  .navbar-collapse {
    // Burger nav (xs-lg screens)
    &.show,
    &.collapsing {
      position: absolute;
      width: 100vw;
      top: 100%;
      z-index: 9995;
      left: 0;
      background-color: white;
      box-shadow: $side-shadow-dark;
      .navbar-nav {
        width: 100%;
        max-width: none !important;
        align-items: center;
        .nav-item {
          padding-top: 1rem !important;
        }
        #navbar-sign-in {
          align-self: center;
          width: 60%;
          max-width: 15rem;
          margin: 1rem 0 1.5rem 0;
          @include md-screen {
            margin-top: 0.5rem;
          }
        }
      }
    }
    // Regular nav (xl screens)
    &.collapse {
      justify-content: flex-end;
      .navbar-nav {
        justify-content: space-between;
        width: 95%;
        max-width: 55rem;
      }
      #navbar-sign-in {
        width: max-content;
      }
    }
    // Nav item
    .nav-link {
      font-family: "Poppins", sans-serif;
      font-size: 1.1rem;
      color: $medium-grey;
      font-weight: 800;
      &:not(.dropdown-toggle) {
        @include underline-animation($medium-grey, 3px, true);
      }
      &:hover,
      &.router-link-active {
        color: $medium-grey;
      }
    }
  }
  // Dropdown on all screens
  .b-nav-dropdown {
    text-align: center;
    & > ul {
      border: none;
      text-align: center;
      @include lg-screen {
        box-shadow: $side-shadow-dark;
        text-align: right;
      }
    }
    .dropdown-item {
      color: $primary-grey;
    }
  }
  .navbar-dropdown-icon {
    font-size: 1.2rem;
    margin-left: 0.2rem;
  }
  .navbar-toggler {
    padding: 0;
  }
}
</style>
