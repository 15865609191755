<template>
  <div class="team-member-photo" :title="name">
    <div class="frame">
      <b-img-lazy class="profile-img" :src="`/images/team/${img}`" :alt="name" />
      <b-img-lazy class="name-img" :src="`/images/team/names/${nameImg}`" :alt="name" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMemberPhoto",
  props: {
    // Name of the image file
    img: {
      type: String,
      required: true
    },
    // Name of the team member
    name: {
      type: String,
      required: true
    },
    // Name of the name image file
    nameImg: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.team-member-photo {
  .frame {
    @include align-center-column;
    box-shadow: $soft-shadow;
    background-color: white;
    width: 40vw;
    max-width: 14rem;
    margin: 0 auto;
    padding-top: 1rem;
  }
  .profile-img {
    width: 80%;
    max-height: 12rem;
    max-width: 12rem;
  }
  .name-img {
    padding: 1rem 0;
    max-width: 100%;
  }
}
</style>