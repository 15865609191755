<template>
  <div class="email-newsletter-button">
    <b-button v-b-modal.email-newsletter-modal class="landing-button primary">
      Subscribe to our newsletter
      <font-awesome-icon :icon="['fas', 'paper-plane']" class="ml-2" />
    </b-button>
    <b-modal id="email-newsletter-modal" hide-footer centered size="lg">
      <EmailNewsletter />
    </b-modal>
  </div>
</template>

<script>
import EmailNewsletter from "./EmailNewsletter";

export default {
  name: "EmailNewsletterButton",
  components: { EmailNewsletter }
};
</script>

<style lang="scss">
.email-newsletter-button {
  .landing-button {
    font-size: 1rem !important;
  }
}
#email-newsletter-modal {
  header {
    border: none
  }
  .email-newsletter {
    box-shadow: none;
    margin-top: -2.5rem;
  }
}
</style>