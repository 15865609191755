<template>
  <b-container v-if="!busy" fluid id="footer">
    <div v-if="totop" class="scroll-top">
      <div class="scroll-wrapper" @click="scrollToTop">
        <b-img-lazy
          id="footer-arrow"
          alt="To top"
          src="/images/chevron_grey.svg"
          title="Scroll To Top"
          draggable="false"
        />
        <b-img-lazy alt="Scroll Decoration" id="footer-curve" src="/images/footer_curve.svg" />
      </div>
    </div>

    <div class="content">
      <div v-show="!hideXeroLogo" id="logo-area" class="text-md-left text-center">
        <a href="/">
          <b-img-lazy
            id="logo-reducer"
            blank-width="192"
            blank-height="70"
            src="/images/reducer_logo_white.svg"
            alt="Reducer logo"
          />
        </a>
        <a
          class="text-centre"
          href="https://apps.xero.com/uk/app/reducer"
          target="_blank"
          rel="noopener"
        >
          <b-img-lazy
            id="logo-xero-partner"
            blank-width="256"
            blank-height="82"
            alt="xero partner"
            src="/images/xero_award_partner.svg"
          />
        </a>
      </div>

      <div class="menu">
        <div class="menu-items-wrapper mr-0 mr-md-auto justify-content-start">
          <div class="menu-items">
            <h5 class="menu-header">Contact</h5>
            <a href="mailto:hello@reducer.co.uk" class="menu-item mb-4">hello@reducer.co.uk</a>
            <p class="menu-item">
              124 City Road
              <br />London <br />EC1V 2NX
            </p>
          </div>
        </div>
        <div class="menu-items-wrapper justify-content-end mr-md-5">
          <div class="menu-items align-items-end align-items-sm-start">
            <h5 class="menu-header">Product</h5>
            <router-link :to="{ name: 'accountants' }" class="menu-item">Accountants</router-link>
            <router-link :to="{ name: 'businesses' }" class="menu-item">Businesses</router-link>
            <router-link :to="{ name: 'how-it-works' }" class="menu-item">How It Works</router-link>
          </div>
        </div>
        <div class="menu-items-wrapper justify-content-start justify-content-md-end mr-md-5">
          <div class="menu-items">
            <h5 class="menu-header">Resources</h5>
            <router-link :to="{ name: 'knowledge-hub' }" class="menu-item"
              >Knowledge Hub</router-link
            >
            <router-link :to="{ name: 'guides' }" class="menu-item">Buying Guides</router-link>
            <router-link :to="{ name: 'blog' }" class="menu-item">Blog</router-link>
            <router-link :to="{ name: 'webinars' }" class="menu-item">Webinars</router-link>
            <router-link :to="{ name: 'accountants-near-me' }" class="menu-item"
              >Find Accountants</router-link
            >
          </div>
        </div>
        <div class="menu-items-wrapper justify-content-end">
          <div class="menu-items align-items-end align-items-sm-start pr-sm-2">
            <h5 class="menu-header">About</h5>
            <router-link :to="{ name: 'about' }" class="menu-item">About Us</router-link>
            <router-link :to="{ name: 'accountant-case-studies' }" class="menu-item"
              >Case Studies</router-link
            >
            <router-link :to="{ name: 'faq' }" class="menu-item">FAQs</router-link>
            <router-link :to="{ name: 'xero' }" class="menu-item">Xero</router-link>
            <router-link :to="{ name: 'quickbooks' }" class="menu-item">QuickBooks</router-link>
            <router-link :to="{ name: 'complaints' }" class="menu-item">Complaints</router-link>
          </div>
        </div>
      </div>
      <div class="social-media">
        <a href="https://www.facebook.com/reduceruk/" target="_blank" rel="noopener">
          <b-img-lazy
            alt="facebook"
            src="/images/social_media/facebook.png"
            height="40"
            width="40"
          />
        </a>
        <a href="https://www.linkedin.com/company/reduceruk" target="_blank" rel="noopener">
          <b-img-lazy
            alt="linkedin"
            src="/images/social_media/linkedin.png"
            height="40"
            width="40"
          />
        </a>
        <a href="https://twitter.com/reduceruk" target="_blank" rel="noopener">
          <b-img-lazy alt="twitter" src="/images/social_media/twitter.png" height="40" width="40" />
        </a>
      </div>
      <div class="pt-5 text-center text-md-left">
        <EmailNewsletterButton />
      </div>
    </div>
    <div class="policies">
      <router-link :to="{ name: 'privacy' }">Privacy policy</router-link> |
      <router-link :to="{ name: 'cookies' }">Cookie policy</router-link> | ©Reducer Ltd
    </div>
  </b-container>
</template>

<script>
import EmailNewsletterButton from "@/components/EmailNewsletterButton";

export default {
  name: "Footer",
  components: { EmailNewsletterButton },
  props: {
    totop: Boolean,
    busy: {
      type: Boolean,
      default: false
    },
    hideXeroLogo: false //requested by quickbooks on certain routes
  },
  created() {
    if (this.$route.name && this.$route.name.startsWith("quickbooks")) {
      this.hideXeroLogo = true;
    }
  },
  watch: {
    $route(to, from, next) {
      if (to.name && to.name.startsWith("quickbooks")) {
        this.hideXeroLogo = true;
      }
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";

#footer {
  @include main-style;
  background-color: $primary-grey;
  padding-bottom: 2rem;
  position: relative;
  .scroll-top {
    @include align-center;
    .scroll-wrapper {
      @include align-center;
      position: relative;
    }
    #footer-arrow {
      :nth-child(2),
      :nth-child(3) {
        margin-right: 2rem;
      }
      width: 2.5rem;
      position: absolute;
      top: -5%;
      right: 39%;
      z-index: 999;
      transition: $smooth-transition;
    }
    #footer-curve {
      transform: rotate(180deg);
      margin: -0.7rem auto 0 auto;
    }
    &:hover {
      cursor: pointer;
      #footer-arrow {
        margin-top: -0.4rem;
      }
    }
  }
  .content {
    padding: 1.5rem 0.5rem 0 0.5rem;
    @include sm-screen {
      padding-left: 10vw;
      padding-right: 10vw;
    }
    @include md-screen {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @include lg-screen {
      padding: 1.5rem 7rem 0 7rem;
    }
    #logo-area {
      @include align-center-column;
      margin-bottom: 1rem;
      @include md-screen {
        justify-content: space-between;
        flex-flow: row;
        margin-left: -0.5rem;
      }
      #logo-reducer {
        width: 12rem;
      }
      #logo-xero-partner {
        width: 12rem;
        margin-top: 1rem;

        @include md-screen {
          width: 16rem;
          margin-top: 0px;
        }
      }
    }
    .menu {
      display: flex;
      flex-flow: row wrap;
      .menu-header {
        color: white;
        font-size: 1.4rem;
        margin-bottom: 1.3rem;
      }
      .menu-items-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 1.5rem;
        width: 50%;
        min-width: 9.5rem;
        @include md-screen {
          width: 15%;
          max-width: 1rem;
          justify-content: flex-end;
        }
      }
      .menu-items {
        display: flex;
        flex-flow: column;
      }
      .menu-item {
        color: white;
        font-size: 1.1rem;
        margin-bottom: 0.8rem;
        width: min-content;
        white-space: nowrap;
      }
      a.menu-item {
        @include underline-animation;
      }
    }
    .social-media {
      padding-top: 2rem;
      flex-wrap: wrap;
      @include align-center;
      @include md-screen {
        justify-content: flex-start;
        padding: 0;
      }
      div,
      a {
        height: 3rem;
        display: flex;
        align-items: flex-end;
        margin-right: 1rem;
        cursor: pointer;
        transition: $smooth-transition;
        &:hover {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  .policies,
  .policies > a {
    padding-top: 2rem;
    @include md-screen {
      padding-top: 3rem;
    }
    text-align: center;
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
}
</style>



