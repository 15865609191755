import Prismic from "@prismicio/client";

const webinars = {
    namespaced: true,

    state: {
        webinars: []
    },

    mutations: {
        updateWebinars(state, webinars) {
            state.webinars = webinars;
        }
    },

    getters: {
        webinars: state => state.webinars
    },

    actions: {
        async fetchWebinars({ commit }) {
            await this._vm.$prismic.client
                .query(Prismic.Predicates.dateAfter('my.webinar.date', new Date(new Date().setHours(0, 0, 0))), { orderings: '[my.webinar.date]' })
                .then(document => {
                    commit("updateWebinars", document)
                })
        }
    }
}

export default webinars;